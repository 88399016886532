<template>
    <div class="preview-page">
        <div class="iphone">
            <img class="iphone-border" src="../../assets/preview-imgs/iphone-border.png"/>
            <iframe ref="iphone" class="iframe" :src="`${$previewUrl}?miniProId=${miniProId}`" frameborder="0"  border="0" scrolling="0"/>
        </div>
        <el-button @click="refreshIframe"
                   type="primary">刷新页面</el-button>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return {
                miniProId:null,
            }
        },
        mounted() {
            this.miniProId = this.$route.query.id;
        },
        methods:{
            refreshIframe(){
                let frame = this.$refs.iphone;
                frame.src = frame.src ;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";
    .preview-page{
        background: #f2f2f2;
        height: 100%;
        width: 100%;
        .flex-center;
        flex-direction: column;
        .iphone{
            height: 70vh;
            width: 36vh;
            min-width: 375px;
            min-height: 735px;
            position: relative;
            margin-bottom: 20px;
            .hidden-scroll-bar;
            .iphone-border{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                pointer-events: none;
            }
            .iframe{
                z-index: 1;
                position: absolute;
                height: 94%;
                width: 86.8%;
                top: 3%;
                left: 6.6%;
                border-radius: 30px;
            }
        }
    }
</style>
